const faq = [
  {
    question: 'Preciso ter CNPJ para emitir boletos com a Cora?',
    answer:
      'Sim, é necessário que sua empresa tenha CNPJ para ter o cadastro aprovado na Cora. Nosso app é destinado para autônomos, profissionais liberais e donos de pequenos e médios negócios, com empresas constituídas.',
    open: false,
  },
  {
    question: 'Vou pagar algum tipo de taxa para abrir a conta na Cora?',
    answer:
      'Não. O aplicativo da Cora é uma solução gratuita. Nenhum valor será cobrado para abertura ou manutenção da conta. Você também tem gratuidade em transferências Pix e TED, além de diversos serviços gratuitos para sua empresa, como a emissão de boletos (com cobrança de R$1,90 por boleto, após a 100ª cobrança compensada no mês).',
    open: false,
  },
  {
    question: 'Posso integrar a Cora com outros sistemas?',
    answer:
      'Sim. É possível integrar a Cora com ERPs e sistemas contábeis elegíveis ao nosso programa de parcerias. Neste caso a integração é realizada por meio de nossa API e é cobrado um valor de acordo com o volume de cobranças. Para saber mais, preencha este formulário de contato.',
    open: false,
  },
  {
    question:
      'É cobrado algum tipo de taxa para receber boletos gerados pelo app?',
    answer:
      'Atualmente, oferecemos aos nossos clientes a vantagem de não cobrar taxas tanto para a emissão quanto para o cancelamento de boletos através do aplicativo e da Cora Web. No entanto, é importante destacar que, apesar da gratuidade na emissão e cancelamento, existem limites para a compensação dos boletos. Para clientes <strong>não MEI’s</strong> a gratuidade é até 100 boletos compensados por mês. Ou seja, a partir do 101º boleto que tenha sido pago pelo seu cliente, haverá uma cobrança de <strong>R$ 1,90</strong> por boleto excedente, que será debitada diretamente da sua conta Cora.<br /><br />Para clientes <strong>MEI’s</strong>, a Cora oferece uma política um pouco diferente. O limite de gratuidade para compensação é de 10 boletos no mês. Sendo assim, a partir do 11º boleto compensado, será cobrada a mesma taxa de <strong>R$ 1,90</strong> por boleto excedente.',
    open: false,
  },
]

export default faq
